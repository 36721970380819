/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.App {
  text-align: center;
}

.header, .footer {
  background-color: #007bff;
  color: white;
  padding: 20px;
}

.header h1, .footer p {
  margin: 0;
}

/* Section Styling */
.section {
  padding: 40px 20px;
  background-color: #ffffff;
  margin-bottom: 20px;
}

/* Benefits Table */
.benefit {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.lead-table {
  width: 100%;
  border-collapse: collapse;
}

.lead-table th, .lead-table td {
  border: 1px solid #ddd;
  padding: 10px;
}

.lead-table th {
  background-color: #007bff;
  color: white;
}

/* Logo Grid */
.logo-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.logo-card {
  flex: 1 1 150px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
  text-align: center;
}

.logo-image {
  max-width: 100%;
  height: auto;
}

/* Form Styles */
.form-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.form-section input[type="url"], .form-section button {
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;

}

qr-code-room-container .qr-code-room canvas {
  width: 150px;  /* Display smaller size */
  height: 150px; /* Display smaller size */
}

.qr-code-container canvas {
  width: 150px;  /* Display smaller size */
  height: 150px; /* Display smaller size */
}

.qr-code-room-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  padding: 20px;
}

.qr-code-room {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.qr-code-room h4 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}
.coupons-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.coupons-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.coupons-section ul {
  list-style-type: none;
  padding: 0;
}

.coupons-section li {
  padding: 8px 0;
  font-size: 1.1rem;
  border-bottom: 1px solid #ddd;
}

.coupons-section li:last-child {
  border-bottom: none;
}
.floating-box {
  position: fixed;
  top: 200px;
  right: 20px;
  background-color: #ff0033;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
}

.floating-box:hover {
  background-color: #1500b3;
}
/* App.css */
/* Updated form layout for labels to the left of inputs */
.form-section form {
  display: flex;
  flex-direction: column;
  max-width: 600px;  /* Set max width for the form */
  margin: 0 auto;  /* Center the form */
}

.form-group {
  display: flex;
  align-items: center;  /* Align label and input vertically in the same row */
  margin-bottom: 15px;  /* Add space between form groups */
}

.form-group label {
  width: 150px;  /* Set width for labels */
  margin-right: 10px;  /* Add space between label and input */
  text-align: right;  /* Right align text in the label */
  font-weight: bold;
}

.form-group input {
  flex: 1;  /* Make input take remaining width of the row */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.form-section button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.form-section button:hover {
  background-color: #0056b3;
}

.whitelist-info {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.whitelist-info h3 {
  margin-bottom: 10px;
  color: #007bff;
}

.whitelist-info ul {
  list-style-type: none;
  padding: 0;
}

.whitelist-info li {
  padding: 5px 0;
  font-size: 1rem;
}

.qr-code-room-container .qr-code-room canvas {
  width: 150px;  /* Display smaller size */
  height: 150px; /* Display smaller size */
}

.qr-code-container canvas {
  width: 150px;  /* Display smaller size */
  height: 150px; /* Display smaller size */
}
/* Form styling */
form div {
  margin-bottom: 15px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
}

input[type="tel"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

.social-sharing button {
  margin-right: 10px;
  padding: 10px;
  font-size: 1rem;
  background-color: #4267B2; /* Facebook color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.social-sharing button:hover {
  background-color: #365899;
}
.qr-code-horizontal-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.qr-code-item {
  text-align: center;
}

.button-group {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

